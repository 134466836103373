<template>
  <div class="identity-auth">
    <h1>账户安全</h1>
    <div class="safe-item" v-for="(item, index) in safeItem" :key="index">
      <el-row class="safe-box">
        <el-col :span="4">
          <i class="icon" :class="item.icon"></i>
        </el-col>
        <el-col :span="16">
          <div class="safe-title">
            <div>{{ item.title }}</div>
              <span>{{ item.detail }}</span>
            </div>
        </el-col>
        <el-col :span="4">
          <el-button @click="toPath(item)">{{ item.btn }}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 修改密码对话框 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="userPwdRef" label-width="90px" :rules="userPwdRules" @submit.native.prevent :model="userPwd">
        <el-form-item label="原始密码:" prop="old_password">
          <el-input type="password" v-model="userPwd.old_password"></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="user_password">
          <el-input type="password" v-model="userPwd.user_password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confim_password">
          <el-input type="password" v-model="userPwd.confim_password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editPwd">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { editPwdApi } from '@/api/user'
  export default {
    data() {
      return {
        safeItem: [
        {
          id: 1,
          icon: 'iconfont icon-xiugaimima',
          path: '#',
          title: '登录密码',
          detail: '建议您定期更改密码保护账户,降低账号被盗风险。',
          btn: '修改密码'
        }, {
          id: 2,
          icon: 'iconfont icon-bangdingshouji',
          path: '#',
          title: '手机认证',
          detail: '您的手机已验证，若已丢失或停用，请立即更换，避免账户被盗。',
          btn: '绑定手机'
        }, {
          id: 3,
          icon: 'iconfont icon-shenfenrenzheng',
          path: '/user/safe/auth',
          title: '身份认证',
          detail: '您还未实名认证该账户，立即实名认证可加快提现速度。',
          btn: '实名认证'
        }],
        userPwd: {
          old_password: '',
          user_password: '',
          confim_password: ''
        },
        userPwdRules: {
          old_password: [{ required: true, message: '请输入原始密码', trigger: 'blur' }],
          user_password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
          confim_password: [{ required: true, message: '请输入确认密码', trigger: 'blur' }]
        },
        dialogVisible: false
      }
    },
    created() {},
    methods: {
      // 修改密码表单提交
      editPwd() {
        this.$refs.userPwdRef.validate(valid => {
          if (!valid) return;
          let confim = this.userPwd.user_password === this.userPwd.confim_password
          if (!confim) return this.$message.error('两次密码输入不一致')
          editPwdApi(this.userPwd).then(res => {
            console.log(res)
            if (res.data.status !== 10000) return this.$message.error(res.data.message)
            this.$message.success(`${res.data.message},请重新登录！`)
            this.$store.commit('setToken', '');
            this.$store.commit('setUserId', '');
            this.$store.commit('setUserAccount', '');
            this.$store.commit('setUserName', '');
            this.$router.push('/login')
            this.handleClose()
          })
        })
      },
      // 关闭对话框
      handleClose() {
        this.dialogVisible = false
        this.$refs.userPwdRef.resetFields()
      },
      toPath(item) {
        switch (item.id) {
        case 1:
          this.dialogVisible = true
          break;
        case 2:
          console.log('666')
          break;
        case 3:
          this.$router.push(item.path)
          break;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .identity-auth {
    line-height: normal;
    margin: 10px 30px;

    .safe-item {
      height: 110px;
      background-color: #f9f9f9;
      margin: 20px 0;
      padding: 25px;
      line-height: 110px;

      .safe-box {
        .icon {
          font-size: 60px;
          margin-left: 20px;
          color: #808080;
        }

        .safe-title {
          text-align: left;
          display: inline-block;
          line-height: 40px;

          div {
            font-size: 25px;
            font-weight: normal;
            margin-top: 20px;
          }

          span {
            font-size: 15px;
          }
        }
      }
    }
  }
</style>